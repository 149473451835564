// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import IDuvalTabConfiguration from "features/detailpage/components/analytics/DuvalTriangle/models/IDuvalTabConfiguration";
import isKinectricsNetworkTransformerModel from "features/detailpage/utils/isKinectricsNetworkTransformerModel";
import isKinectricsNetworkTransformerSiliconeModel from "features/detailpage/utils/isKinectricsNetworkTransformerSiliconeModel";
import { useMemo } from "react";
import type1Default from "../constants/type1Default";
import type1_SeedDefault from "../constants/type1_SeedDefault";
import type1_SiliconeDefault from "../constants/type1_SiliconeDefault";
import type1_SoyDefault from "../constants/type1_SoyDefault";
import type1_SyntheticDefault from "../constants/type1_SyntheticDefault";
import type3Default from "../constants/type3Default";
import type4Default from "../constants/type4Default";
import type5Default from "../constants/type5Default";

const useDuvalTabs = (implementationId: string) => {
  const isKinectricsModel = useMemo<boolean>(() => {
    return isKinectricsNetworkTransformerModel(implementationId.toLowerCase());
  }, [implementationId]);

  const isKinectricsSiliconeModel = useMemo<boolean>(() => {
    return isKinectricsNetworkTransformerSiliconeModel(
      implementationId.toLowerCase()
    );
  }, [implementationId]);

  const showPentagons = useMemo<boolean>(() => {
    return !isKinectricsModel;
  }, [isKinectricsModel]);

  const duvalConfiguration = useMemo<IDuvalTabConfiguration>(() => {
    return isKinectricsSiliconeModel
      ? {
          type1: [],
          type1_Synthetic: [],
          type1_Seed: [],
          type1_Silicone: [],
          type1_Soy: [],
          type3: type3Default,
          type4: [],
          type5: isKinectricsModel ? [] : type5Default
        }
      : {
          type1: type1Default,
          type1_Synthetic: type1_SyntheticDefault,
          type1_Seed: type1_SeedDefault,
          type1_Silicone: type1_SiliconeDefault,
          type1_Soy: type1_SoyDefault,
          type3: [],
          type4: type4Default,
          type5: isKinectricsModel ? [] : type5Default
        };
  }, [isKinectricsModel, isKinectricsSiliconeModel]);

  return { duvalConfiguration, showPentagons };
};

export default useDuvalTabs;
