// Copyright 2016-2023 Hitachi Energy. All rights reserved.

export const config = {
  app: {
    name: "APM",
    localesApiUrl: "Translations/{locale}",
    versionFileUrl: "version.json"
  },
  api: {
    security: {
      csrfTokenUrl: "Security/GetCsrfToken"
    },
    map: {
      config: "Settings/MapConfiguration"
    },
    dataIntegration: {
      modelsUrl: "ModelAdmin"
    },
    filters: "filters",
    homepage: {
      openIssuesInfo: "Homepage/OpenIssuesInfo",
      assetRiskTrend: "Homepage/AssetRiskTrend",
      assetRiskIndicators: "Homepage/AssetRiskIndicators",
      assetsSummary: {
        trendsUrl: "AssetsSummary/Trends"
      },
      keyRiskChanges: "Homepage/KeyRiskChanges",
      assetRiskVisualization: "homepage/NumberOfAssetRisk"
    },
    stationRisk: {
      allLocationsRisks: "StationRisks",
      filteredLocationsRisks: "StationRisks/filtered"
    },
    assetQuery: {
      assetsByRange: "asset-info/range",
      assetsExcelExport: "XlsxExport/AssetList",
      assetsCsvExport: "CsvExport/AssetsList",
      deleteAsset: "Asset?assetId={assetId}"
    },
    alarms: {
      alarms: "AssetMessageLogAlarm/range",
      read: "AssetMessageLogAlarm/Ack",
      count: "AssetMessageLogAlarm/AlarmTypeCount"
    },
    assetRisk: {
      assetRiskByAssetType: "AssetRisk/NumberOfAssetRiskByAssetType",
      assetRiskVisualization: "AssetRiskVisualization/NumberOfAssetRisk",
      componentRiskSummary: "components-summary/range"
    },
    assetRiskChanges: {
      assetRiskChangesRange: "AssetRiskChangeGrid"
    },
    assetRiskDashboard: {
      assetRiskTrend: "AssetRiskTrend"
    },
    detailPage: {
      assetAllParametersTrendUrl: "Asset/ParameterTrend?assetId={assetId}",
      assetDetailsUrl: "Asset/AssetDetails?assetId={assetId}",
      assetDetailsExcelExportUrl: "XlsxExport/AssetDetails",
      assetDuvalTrianglesGasesConcentrationUrl:
        "Visualizations/DuvalTriangle?assetId={assetId}&dataSource={dataSource}",
      assetDuvalPentagonsGasesConcentrationUrl:
        "Visualizations/DuvalPentagon?assetId={assetId}&dataSource={dataSource}",
      assetParametersInfoUrl: "Asset/ParametersInfo?assetId={assetId}",
      assetParametersHistogram:
        "AssetParametersHistogram?assetId={assetId}&parameterName={parameterName}",
      assetDerivativeParametersHistogram:
        "AssetDerivativeParametersHistogram?assetId={assetId}&parameterName={parameterName}",
      assetParametersExcelExportUrl: "XlsxExport/AssetParameters",
      assetParametersCsvExportUrl: "CsvExport/AssetParameters",
      assetParameterTrendUrl:
        "Asset/ParameterTrendWithThresholds/{parameterName}?assetId={assetId}",
      derivativeParameterTrendUrl:
        "Asset/DerivativeParameterTrend/{parameterName}?assetId={assetId}",
      assetSubfactorsUrl: "Asset/Subfactors?assetId={assetId}",
      assetStandardOilParametersTrendValuesUrl:
        "Visualizations/SOT?assetId={assetId}",
      thermalVisualizationUrl: "TRXCharts/Thermal?assetId={assetId}",
      rogersRatioUrl: "TRXCharts/RogersRatio?assetId={assetId}",
      ltcOperationsUrl: "TRXCharts/LTC/Operations?assetId={assetId}",
      ltcSwitchOperationsHistoryUrl:
        "TRXCharts/LTC/SwitchOperationsHistory?assetId={assetId}",
      dgaOperationsUrl: "TRXCharts/LTC/DGAOperations?assetId={assetId}",
      assetTreeUrl: "AssetTree?assetId={assetId}",
      dissolvedDataSourceGasAnalysisUrl:
        "Visualizations/DGA?assetId={assetId}&dataSource={dataSource}",
      dissolvedGasAdvancedAnalysisUrl: "Visualizations/DGA/gas/{gasName}",
      offlineBushingVisualizationUrl:
        "TRXCharts/OfflineBushing?assetId={assetId}",
      onlineBushingVisualizationUrl:
        "TRXCharts/OnlineBushing?assetId={assetId}",
      agingOperationsUrl: "CBKCharts/Aging/Operations?assetId={assetId}",
      agingContactWearUrl: "CBKCharts/Aging/ContactWear?assetId={assetId}",
      dielectricPressureUrl: "CBKCharts/Dielectric/Pressure?assetId={assetId}",
      dielectricTimedPressureUrl:
        "CBKCharts/Dielectric/TimedPressure?assetId={assetId}",
      dielectricPressureParametersUrl:
        "CBKCharts/Dielectric/PressureParameters?assetId={assetId}",
      dielectricMoistureUrl: "CBKCharts/Dielectric/Moisture?assetId={assetId}",
      agingVisualizationUrl:
        "Asset/GenericAgingVisualization?assetId={assetId}",
      degradationScoreTrendUrl: "Asset/DegradationScoreTrend?assetId={assetId}",
      partialDischargeElectricalUrl:
        "TRXCharts/PartialDischarge/Electrical?assetId={assetId}",
      partialDischargeUltraHighFrequencyUrl:
        "TRXCharts/PartialDischarge/UltraHighFrequency?assetId={assetId}",
      voltageStressVisualizationsUrl:
        "Visualizations/VoltageStress?assetId={assetId}&chartGroup={chartGroup}",
      replacementPriorityScoreCalculationUrl:
        "Asset/ReplacementPriorityScoreCalculation?assetId={assetId}",
      issues: {
        createCommentUrl: "Issues/{issueId}/Comments",
        createIssueUrl: "Assets/Issues?assetId={assetId}",
        getIssueUrl: "Issues/{issueId}/",
        editIssueUrl: "Issues/{issueId}/",
        editIssueStatusUrl: "Issues/{issueId}/Status",
        issuesUrl: "Assets/Issues?assetId={assetId}",
        statusesUrl: "Issues/Statuses",
        urgenciesUrl: "Issues/UrgencyLevelItems",
        workOrdersUrl: "Assets/WorkOrders?assetId={assetId}"
      },
      messages: {
        messagesUrl: "MessageLogs?assetId={assetId}",
        messagesExcelExportUrl: "XlsxExport/AssetMessages",
        messagesCsvExportUrl: "CsvExport/AssetMessages"
      },

      modelConfigurationUrl:
        "ModelConfigurations/{modelId}/configurations/{configurationName}",
      nameplateUrl: "Asset/NameplateWithModelInfo?assetId={assetId}",
      parameterFamilyInfoUrl:
        "Assets/ParameterFamilyStatistics/{parameterName}?assetId={assetId}",
      derivativeParameterFamilyInfoUrl:
        "Assets/DerivativeParameterFamilyStatistics/{parameterName}?assetId={assetId}",
      degradationScoreFamilyInfoUrl:
        "Assets/DegradationScoreFamilyStatistics?assetId={assetId}",
      replacementScoreFamilyInfoUrl:
        "Assets/ReplacementScoreFamilyStatistics?assetId={assetId}",
      degradationSubScoreFamilyInfoUrl:
        "Assets/DegradationSubScoreFamilyStatistics?assetId={assetId}&subScoreName={subScoreName}&level={level}",
      importanceScoreFamilyInfoUrl:
        "Assets/ImportanceScoreFamilyStatistics?assetId={assetId}",
      batteryConductanceUrl: "BatteryConductance?assetId={assetId}",
      batteryVoltageUrl: "BatteryVoltage?assetId={assetId}",
      widgets: {
        RiskMatrix: {
          RiskMatrixChart: {
            modelConfigUrl: "Asset/AssetRiskConfiguration/{modelId}/",
            clustersFleetUrl: "AssetPoints/clusters/fleet",
            clustersLocationUrl: "AssetPoints/clusters/location",
            riskMatrixConfigurationUrl: "RiskMatrixConfiguration"
          }
        }
      },
      simulation: {
        configUrl: "simulation-configs",
        requestUrl: "simulations",
        summaryUrl: "simulations",
        deleteUrl: "simulations/{simulationId}",
        trendUrl: "simulation-results/{simulationId}",
        pinUrl: "simulations/{simulationId}/IsPinned"
      },
      parametersMechanicalUrl: "CBKMechanical",
      auxiliariesAcknowledgeUrl:
        "Commands/Auxiliaries/SetAcknowledge?parameterName={parameterName}&assetId={assetId}",
      parametersAcknowledgeUrl:
        "Commands/SetAcknowledge?parameterName={parameterName}&assetId={assetId}",
      parametersAdjustUrl:
        "Commands/SetThreshold?parameterName={parameterName}&assetId={assetId}",
      parametersWearUrl: "CBKCharts/Wear",
      parametersHeaterStatusUrl:
        "CBKAuxiliaries/HeaterStatus?assetId={assetId}",
      parametersOperatingMechanismMotorUrl:
        "CBKAuxiliaries/OperatingMotorMechanism?assetId={assetId}",
      parametersMotorStartsUrl: "CBKAuxiliaries/MotorStarts?assetId={assetId}",
      parametersOperationsUrl: "Events/EventData",
      parametersOperationsEventsUrl:
        "Events/EventIds?assetId={assetId}",
      logUrl: "Logs/Range",
      logsFile: "Export/Logs",
      dcRollingAverageOfVectorLengthUrl:
        "transformers/visualizations/hvdc/lines/rolling-average-of-vector-length?assetId={assetId}",
      dcMeasuredApparentCapacitanceUrl:
        "transformers/visualizations/hvdc/lines/measured-apparent-capacitance?assetId={assetId}",
      dcOilTemperatureUrl:
        "transformers/visualizations/hvdc/lines/oil-temperature?assetId={assetId}",
      dcOLTCOilLevelAndTemperatureUrl:
        "transformers/visualizations/hvdc/lines/oltc-oil-level-with-temperature?assetId={assetId}",
      dcCircleTemperatureUrl:
        "transformers/visualizations/hvdc/circles/temperature?assetId={assetId}",
      dcCircleBushingUrl:
        "transformers/visualizations/hvdc/circles/bushing?assetId={assetId}",
      dcCircleOltcOilLevelUrl:
        "transformers/visualizations/hvdc/circles/oltc-oil-level?assetId={assetId}",
      dcCoreEarthingCurrentUrl:
        "transformers/visualizations/hvdc/lines/core-earthing-current?assetId={assetId}",
      dcCircleCoreEarthingCurrentUrl:
        "transformers/visualizations/hvdc/circles/core-earthing-current/rolling-average?assetId={assetId}",
      dcMeasurementsUrl: "transformers/visualizations/hvdc/measurements",
      dcRollingAverageForAssetUrl:
        "transformers/visualizations/hvdc/rolling-average/asset"
    },
    configurationTool: {
      nameplateUrl: "Nameplate?assetId={assetId}",
      inspectionUrl: "Inspections/Batch?assetId={assetId}",
      parametersUrl: "Asset/Parameters?assetId={assetId}",
      assetDeviceUrl: "AssetDevice?assetId={assetId}",
      assetsStatusesUrl: "AssetsStatusesMap?effective={effective}",
      assetDeviceCsvImportParametersUrl:
        "AssetDevice/ValidateConnectedDevicesCsv"
    },
    locations: {
      locationsRangeUrl: "locations/range",
      assetsUrl: "locations/assets",
      issuesUrl: "locations/assets-with-issues"
    },
    watchlist: {
      actionsUrl: "Watchlist/{issueId}/Actions",
      issueCloseUrl: "Issue/{issueId}/Close",
      issuesRangeUrl: "Watchlist/Range",
      issueExcelExportUrl: "XlsxExport/AssetsIssues",
      issueCsvExportUrl: "CsvExport/AssetsIssues",
      updateIssueStatusUrl: "Issues/{issueId}/Status"
    },
    reports: {
      dashboardExternalReports: "Reports/{reportType}/ReportLinks",
      detailPageExternalReports:
        "Reports/{reportType}/Asset/ReportLinks?assetId={assetId}",
      deleteUrl: "Reports/{datasetId}/Delete",
      importUrl: "Reports/Upload"
    },
    powerbiIntegrated: {
      accessTokenUrl: "PowerBiIntegrated/AccessToken",
      loginUrl: "PowerBiIntegrated/Login?replyUrl={replyUrl}"
    },
    powerbiembed: {
      reports: "Reports",
      reportsByPrefix: "Reports/ReportsByPrefix?prefix={prefix}",
      report: "Reports/Report?name={name}",
      templates: "Reports/Templates",
      template: "Reports/Template/{name}/Get"
    },
    user: {
      userUrl: "User",
      loginUrl: "User/Login?redirectTo={redirectTo}",
      logoutUrl: "User/Logout?redirectTo={redirectTo}",
      customerUrl: "User/Customer",
      getTenantsListUrl: "User/TenantsList",
      switchTenant: "User/SwitchTenant?tenantName={tenantName}",
      environmentUrl: "Settings/Environment",
      visualizationsUrl: "CustomerVisualizations",
      visualizationsAssetGridUrl: "CustomerVisualizations/AssetsGrid",
      visualizationsIssuesGridUrl: "CustomerVisualizations/IssuesGrid",
      visualizationsCommandIntegrationUrl:
        "CustomerVisualizations/CommandIntegration",
      issueSettingsUrl: "issue-settings/status-change-settings"
    },
    settings: {
      uploadDataImportTimeout: 600000,
      data: {
        xlsxDataImportUrl: "XlsxImport/XlsxData/",
        templatesInfo: "Settings/TemplatesInfo",
        template: "Settings/Template/{name}/",
        inspectionUrl: "Inspections/BatchMany/"
      },
      parameter: {
        dropModelUrl: "ModelAdmin/{modelId}/",
        modelConfigUrl: "ModelAdmin/{modelId}/ModelConfig",
        updateModelConfigUrl: "ModelAdmin/UpdateModelConfig",
        createModelConfigUrl: "ModelAdmin/CreateModelConfig/{modelType}",
        updateExternalModelConfigUrl: "ModelAdmin/UpdateExternalModelConfig",
        modelParameterSourcesUrl: "ModelAdmin/ModelParameterSources",
        modelParameterInputPossibleValuesUrl:
          "ModelAdmin/{modelId}/InputPossibleValues",
        XlsxInspectionDataSourceUrl:
          "ModelAdmin/{modelId}/TemplateName/{templateName}"
      }
    },
    modelTrigger: {
      model: "commands/modeltrigger-ui?modelId={modelId}",
      asset: "commands/modeltrigger-ui?modelId={modelId}&assetId={assetId}"
    },
    notifications: "Notifications",
    familyAnalytics: {
      assetParametersUrl:
        "Assets/ParameterLatestValues/{parameterName}?assetId={assetId}",
      assetDerivativeParametersUrl:
        "Assets/DerivativeParameterLatestValues/{parameterName}",
      degradationScoreUrl: "Assets/DegradationScoreLatestValues",
      degradationSubScoreUrl:
        "Assets/DegradationSubScoreLatestValues?subScoreName={subScoreName}&level={level}",
      replacementScoreUrl: "Assets/ReplacementScoreLatestValues",
      maintenancePriorityScoreUrl:
        "Assets/MaintenancePriorityScoreLatestValues?assetId={assetId}",
      scatterPlotFilterUrl: "Assets/AssetFamilySubFilters"
    },
    licenses: {
      nuget: "licenses/nuget",
      maven: "licenses/maven",
      common: "licenses/common",
      licenseText: "licenses/{licenseType}/text/{fileName}"
    },
    document: {
      releaseNotes: "Document/ReleaseNotes",
      userGuide: "Document/UserGuide"
    }
  },
  powerbi: {
    reports: {
      views: {
        assetsView: "AssetsView",
        assetParameters: "AssetParameters"
      },
      columns: {
        assetId: "AssetId",
        organization: "Organization",
        region: "Region",
        location: "Location"
      }
    }
  },
  UI: {
    supportContactUrl:
      "https://www.hitachienergy.com/offering/solutions/asset-and-work-management/services/support",
    pbiUrl: "https://app.powerbi.com",
    homepage: {
      stationRisk: {
        stationReportName: "Location Summary"
      }
    }
  }
};
