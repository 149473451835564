// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Col, Row } from "antd";
import DataSource from "common/DuvalAnalysis/models/DataSource";
import ResizeWrapper from "common/ResizeWrapper";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import FeatureFlagsContext from "contexts/FeatureFlagsContext";
import { VisualizationKeys } from "core/app/components/FeatureToggle";
import { Statuses } from "core/data/models/Data";
import UrlService from "core/data/services/UrlService";
import $ from "jquery";
import React from "react";
import { IntlShape, injectIntl } from "react-intl";
import { config } from "utils/AppConfig";
import { IData } from "utils/WebService";
import { DuvalDataPoint, DuvalDataType } from "./DuvalTriangle";
import DuvalTriangleChart from "./DuvalTriangleChart";
import DuvalTriangleType1Note from "./DuvalTriangleType1Note";
import DuvalTriangleType4Note from "./DuvalTriangleType4Note";
import DuvalTriangleType5Note from "./DuvalTriangleType5Note";
import DuvalType from "./models/DuvalType";
import IDuvalRegion from "./models/IDuvalRegion";
import IDuvalTabConfiguration from "./models/IDuvalTabConfiguration";

interface ITriangleData {
  type1: IDuvalPoint[];
  type1_Synthetic: IDuvalPoint[];
  type1_Seed: IDuvalPoint[];
  type1_Silicone: IDuvalPoint[];
  type1_Soy: IDuvalPoint[];
  type3: IDuvalPoint[];
  type4: IDuvalPoint[];
  type5: IDuvalPoint[];
}

interface IDuvalPoint {
  date: Date;
  left: number;
  right: number;
  base: number;
}

interface IDuvalTrianglesTabState {
  triangleData: IData<ITriangleData>;
}

interface IDuvalTrianglesTabProps {
  assetId: string;
  dataSource: DataSource;
  intl: IntlShape;
  duvalConfiguration: IDuvalTabConfiguration;
}

class DuvalTrianglesTab extends React.Component<
  IDuvalTrianglesTabProps,
  IDuvalTrianglesTabState
> {
  constructor(props: IDuvalTrianglesTabProps) {
    super(props);
    this.getAnalyticsWidget = this.getAnalyticsWidget.bind(this);

    this.state = {
      triangleData: {
        data: {
          type1: [],
          type1_Synthetic: [],
          type1_Seed: [],
          type1_Silicone: [],
          type1_Soy: [],
          type3: [],
          type4: [],
          type5: []
        },
        message: "",
        status: Statuses.Loading
      }
    };
  }

  componentWillReceiveProps = (nextProps: IDuvalTrianglesTabProps) => {
    if (
      this.props.dataSource !== nextProps.dataSource ||
      this.props.assetId !== nextProps.assetId
    )
      this.setState({
        triangleData: {
          data: {
            type1: [],
            type1_Synthetic: [],
            type1_Seed: [],
            type1_Silicone: [],
            type1_Soy: [],
            type3: [],
            type4: [],
            type5: []
          },
          message: "",
          status: Statuses.Loading
        }
      });
    queryForParameters(
      nextProps.assetId,
      nextProps.dataSource,
      this.onSuccess,
      this.onError
    );
  };

  componentDidMount() {
    queryForParameters(
      this.props.assetId,
      this.props.dataSource,
      this.onSuccess,
      this.onError
    );
  }

  private static convertToDuvalDataPoints(
    points: IDuvalPoint[]
  ): DuvalDataPoint[] {
    return (points || []).map((p) =>
      p ? new DuvalDataPoint(p.left, p.right, p.base, p.date) : null
    );
  }

  private static duvalConfigurationExists(configuration: IDuvalRegion[]) {
    return configuration?.length > 0;
  }

  private static showDuvalTriangle(
    duvalType: DuvalType,
    duvalConfiguration: IDuvalTabConfiguration
  ): boolean {
    switch (duvalType) {
      case DuvalType.Type1_Mineral:
        return DuvalTrianglesTab.duvalConfigurationExists(
          duvalConfiguration?.type1
        );
      case DuvalType.Type3:
        return DuvalTrianglesTab.duvalConfigurationExists(
          duvalConfiguration?.type3
        );
      case DuvalType.Type4:
        return DuvalTrianglesTab.duvalConfigurationExists(
          duvalConfiguration?.type4
        );
      case DuvalType.Type5:
        return DuvalTrianglesTab.duvalConfigurationExists(
          duvalConfiguration?.type5
        );
      case DuvalType.Type1_Soy:
        return DuvalTrianglesTab.duvalConfigurationExists(
          duvalConfiguration?.type1_Soy
        );
      case DuvalType.Type1_Silicone:
        return DuvalTrianglesTab.duvalConfigurationExists(
          duvalConfiguration?.type1_Silicone
        );
      case DuvalType.Type1_Seed:
        return DuvalTrianglesTab.duvalConfigurationExists(
          duvalConfiguration?.type1_Seed
        );
      case DuvalType.Type1_Synthetic:
        return DuvalTrianglesTab.duvalConfigurationExists(
          duvalConfiguration?.type1_Synthetic
        );
    }
  }

  getAnalyticsWidget(): JSX.Element {
    const GeneralDuvals = (
      <>
        {DuvalTrianglesTab.showDuvalTriangle(
          DuvalType.Type1_Mineral,
          this.props.duvalConfiguration
        ) && (
          <Col span={8}>
            <ResizeWrapper>
              <DuvalTriangleChart
                dataSource={this.props.dataSource}
                points={DuvalTrianglesTab.convertToDuvalDataPoints(
                  this.state.triangleData.data.type1
                )}
                type={DuvalDataType.Type1}
                regions={this.props.duvalConfiguration.type1}
                labels={{ left: "CH4", right: "C2H4", base: "C2H2" }}
              />
            </ResizeWrapper>
          </Col>
        )}
        {DuvalTrianglesTab.showDuvalTriangle(
          DuvalType.Type3,
          this.props.duvalConfiguration
        ) && (
          <Col span={8}>
            <ResizeWrapper>
              <DuvalTriangleChart
                dataSource={this.props.dataSource}
                points={DuvalTrianglesTab.convertToDuvalDataPoints(
                  this.state.triangleData.data.type3
                )}
                type={DuvalDataType.Type3}
                regions={this.props.duvalConfiguration.type3}
                labels={{ left: "CH4", right: "C2H4", base: "C2H2" }}
              />
            </ResizeWrapper>
          </Col>
        )}
        {DuvalTrianglesTab.showDuvalTriangle(
          DuvalType.Type4,
          this.props.duvalConfiguration
        ) && (
          <Col span={8}>
            <ResizeWrapper>
              <DuvalTriangleChart
                dataSource={this.props.dataSource}
                points={DuvalTrianglesTab.convertToDuvalDataPoints(
                  this.state.triangleData.data.type4
                )}
                type={DuvalDataType.Type4}
                regions={this.props.duvalConfiguration.type4}
                note={<DuvalTriangleType4Note />}
                labels={{ left: "H2", right: "CH4", base: "C2H6" }}
              />
            </ResizeWrapper>
          </Col>
        )}
        {DuvalTrianglesTab.showDuvalTriangle(
          DuvalType.Type5,
          this.props.duvalConfiguration
        ) && (
          <Col span={8}>
            <ResizeWrapper>
              <DuvalTriangleChart
                dataSource={this.props.dataSource}
                points={DuvalTrianglesTab.convertToDuvalDataPoints(
                  this.state.triangleData.data.type5
                )}
                type={DuvalDataType.Type5}
                regions={this.props.duvalConfiguration.type5}
                note={<DuvalTriangleType5Note />}
                labels={{ left: "CH4", right: "C2H4", base: "C2H6" }}
              />
            </ResizeWrapper>
          </Col>
        )}
      </>
    );

    // TODO: should be removed after the feature is implemented
    // https://pgga-es.atlassian.net/browse/APM-16311
    const KEY = "NewFluidsDuvalTypeKey";
    const duvalType = localStorage.getItem(KEY) || DuvalType.Type1_Soy;

    const NewFluidsDuvals = (
      <>
        {duvalType === DuvalType.Type1_Soy &&
          DuvalTrianglesTab.showDuvalTriangle(
            DuvalType.Type1_Soy,
            this.props.duvalConfiguration
          ) && (
            <Col span={8}>
              <ResizeWrapper>
                <DuvalTriangleChart
                  isNewFluidsFeature
                  dataSource={this.props.dataSource}
                  points={DuvalTrianglesTab.convertToDuvalDataPoints(
                    this.state.triangleData.data.type1_Soy
                  )}
                  type={DuvalDataType.Type1}
                  regions={this.props.duvalConfiguration.type1_Soy}
                  labels={{ left: "CH4", right: "C2H4", base: "C2H2" }}
                  note={<DuvalTriangleType1Note />}
                />
              </ResizeWrapper>
            </Col>
          )}
        {duvalType === DuvalType.Type1_Seed &&
          DuvalTrianglesTab.showDuvalTriangle(
            DuvalType.Type1_Seed,
            this.props.duvalConfiguration
          ) && (
            <Col span={8}>
              <ResizeWrapper>
                <DuvalTriangleChart
                  isNewFluidsFeature
                  dataSource={this.props.dataSource}
                  points={DuvalTrianglesTab.convertToDuvalDataPoints(
                    this.state.triangleData.data.type1_Seed
                  )}
                  type={DuvalDataType.Type1}
                  regions={this.props.duvalConfiguration.type1_Seed}
                  labels={{ left: "CH4", right: "C2H4", base: "C2H2" }}
                  note={<DuvalTriangleType1Note />}
                />
              </ResizeWrapper>
            </Col>
          )}
        {duvalType === DuvalType.Type1_Silicone &&
          DuvalTrianglesTab.showDuvalTriangle(
            DuvalType.Type1_Silicone,
            this.props.duvalConfiguration
          ) && (
            <Col span={8}>
              <ResizeWrapper>
                <DuvalTriangleChart
                  isNewFluidsFeature
                  dataSource={this.props.dataSource}
                  points={DuvalTrianglesTab.convertToDuvalDataPoints(
                    this.state.triangleData.data.type1_Silicone
                  )}
                  type={DuvalDataType.Type1}
                  regions={this.props.duvalConfiguration.type1_Silicone}
                  labels={{ left: "CH4", right: "C2H4", base: "C2H2" }}
                  note={<DuvalTriangleType1Note />}
                />
              </ResizeWrapper>
            </Col>
          )}
        {duvalType === DuvalType.Type1_Synthetic &&
          DuvalTrianglesTab.showDuvalTriangle(
            DuvalType.Type1_Synthetic,
            this.props.duvalConfiguration
          ) && (
            <Col span={8}>
              <ResizeWrapper>
                <DuvalTriangleChart
                  isNewFluidsFeature
                  dataSource={this.props.dataSource}
                  points={DuvalTrianglesTab.convertToDuvalDataPoints(
                    this.state.triangleData.data.type1_Synthetic
                  )}
                  type={DuvalDataType.Type1}
                  regions={this.props.duvalConfiguration.type1_Synthetic}
                  labels={{ left: "CH4", right: "C2H4", base: "C2H2" }}
                  note={<DuvalTriangleType1Note />}
                />
              </ResizeWrapper>
            </Col>
          )}
      </>
    );

    return (
      <FeatureFlagsContext.Consumer>
        {({ isFeatureAvailable }) => {
          const isNewFluidsFeature = isFeatureAvailable(
            VisualizationKeys.Duval_New_Fluids
          );
          return (
            <Row>{!isNewFluidsFeature ? GeneralDuvals : NewFluidsDuvals}</Row>
          );
        }}
      </FeatureFlagsContext.Consumer>
    );
  }

  private getNoDataComponent(): JSX.Element {
    return <WidgetNoDataMessage />;
  }

  private getLoadingComponent(): JSX.Element {
    return <Processing />;
  }

  private getErrorComponent(): JSX.Element {
    return (
      <WidgetErrorMessage
        messageId="global.empty"
        messageDefault={""}
        messageValues={""}
      />
    );
  }

  private onSuccess = (data: ITriangleData): void => {
    this.setState(
      (
        prevState: IDuvalTrianglesTabState,
        props: IDuvalTrianglesTabProps
      ): IDuvalTrianglesTabState => {
        return {
          triangleData: {
            data: {
              ...data,
              // TODO: reused data from type1, to be fixed after https://pgga-es.atlassian.net/browse/APM-16311
              type1_Synthetic: data.type1,
              type1_Seed: data.type1,
              type1_Silicone: data.type1,
              type1_Soy: data.type1
            },
            status: Statuses.Succeeded,
            message: ""
          }
        };
      }
    );
  };

  private onError = (xhr: JQueryXHR, status: any, err: any): void => {
    this.setState(
      (
        prevState: IDuvalTrianglesTabState,
        props: IDuvalTrianglesTabProps
      ): IDuvalTrianglesTabState => {
        return {
          triangleData: {
            data: {
              type1: [],
              type1_Soy: [],
              type1_Seed: [],
              type1_Silicone: [],
              type1_Synthetic: [],
              type3: [],
              type4: [],
              type5: []
            },
            status: xhr.status === 404 ? Statuses.NotFound : Statuses.Failed,
            message: status.concat(" ").concat(err.toString())
          }
        };
      }
    );
  };

  private getComponentByStatus(): JSX.Element {
    switch (this.state.triangleData.status) {
      case Statuses.Loading:
        return this.getLoadingComponent();
      case Statuses.Succeeded:
        return this.getAnalyticsWidget();
      case Statuses.NotFound:
        return this.getNoDataComponent();
      default:
        return this.getErrorComponent();
    }
  }

  render() {
    return (
      <div className="analytics duval-triangles" data-qa="duval-triangles">
        {this.getComponentByStatus()}
      </div>
    );
  }
}

function queryForParameters(
  assetId: string,
  dataSource: string,
  onSuccess: (data: ITriangleData | null | undefined) => void,
  onError: (xhr: JQueryXHR, status: any, err: any) => void
): void {
  const url = UrlService.getApiUrl(
    config.api.detailPage.assetDuvalTrianglesGasesConcentrationUrl,
    [
      {
        name: "assetId",
        value: assetId
      },
      {
        name: "dataSource",
        value: dataSource
      }
    ]
  );
  $.ajax({
    url: url,
    type: "GET",
    dataType: "json",
    cache: false,
    success: onSuccess,
    error: onError,
    contentType: "application/json; charset=UTF-8",
    processData: false
  });
}

export default injectIntl(DuvalTrianglesTab, {
  forwardRef: true
});
