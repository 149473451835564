// Copyright 2016-2023 Hitachi Energy. All rights reserved.

enum DuvalType {
  Type1_Mineral = "Type1_Mineral",
  Type1_Silicone = "Type1_Silicone",
  Type1_Soy = "Type1_Soy",
  Type1_Synthetic = "Type1_Synthetic",
  Type1_Seed = "Type1_Seed",
  Type3 = "Type3",
  Type4 = "Type4",
  Type5 = "Type5"
}

export default DuvalType;
