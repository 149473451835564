// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { notifications } from "@pg/common";
import Container from "common/Container";
import DataGrid, { IColumn } from "common/datagrid/DataGrid";
import { IColumnOrder } from "common/datagrid/models/IColumnOrder";
import SortOrders from "common/datagrid/models/SortOrders";
import { SelectedFilters, StatusBar } from "common/FilterBar";
import SectionName from "components/common/SectionName";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import Data from "core/data/models/Data";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import getCustomerVisualizationsIssueGridSelector from "core/selectors/getCustomerVisualizationsIssueGridSelector";
import IssueStatusChangeConfirmationModalContainer from "features/detailpage/features/issues/containers/IssueStatusChangeConfirmationModalContainer";
import saveAs from "file-saver";
import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { useAppSelector } from "store";
import { config } from "utils/AppConfig";
import useIssueGrid from "../hooks/useIssueGrid";
import {
  IOnMaintenancePriorityClickOptions,
  IOnNumberOfActionsClickOptions
} from "../hooks/useIssuesGridColumnsConfig";

interface IIssueGridState {
  user: Data<IUser>;
}

interface IIssueGridOwnProps {
  filters: SelectedFilters;
  onMaintenancePriorityClick?: (
    options: IOnMaintenancePriorityClickOptions
  ) => void;
  onNumberOfActionsClick?: (options: IOnNumberOfActionsClickOptions) => void;
}

type IIssueGridProps = IIssueGridOwnProps & IIssueGridState;

const IssuesGrid = ({
  filters,
  onMaintenancePriorityClick,
  onNumberOfActionsClick,
  user
}: IIssueGridProps) => {
  const intl = useIntl();

  const customerVisualizationsIssueGrid = useAppSelector(
    getCustomerVisualizationsIssueGridSelector
  );

  const {
    actions,
    dataEndpoint,
    rowsTotal,
    columns,
    modalVisible,
    modalTitle,
    handleCloseIssueConfirm,
    hideCloseIssueModal
  } = useIssueGrid({
    filters,
    onMaintenancePriorityClick,
    onNumberOfActionsClick,
    user: user.data
  });

  const stringToSortOrder = React.useCallback(
    (sortOrder: SortOrders): string => {
      switch (sortOrder) {
        case SortOrders.Asc:
          return "asc";
        case SortOrders.Desc:
          return "desc";
        default:
          return "none";
      }
    },
    []
  );

  const mapColumns = React.useCallback(
    (columns: IColumn[]): IColumnOrder[] => {
      return columns
        .filter((c) => c.state.sortOrder !== SortOrders.None)
        .sort((a, b) => a.state.groupOrder - b.state.groupOrder)
        .map<IColumnOrder>((c) => ({
          columnId: c.config.id,
          sortOrder: stringToSortOrder(c.state.sortOrder)
        }));
    },
    [stringToSortOrder]
  );

  const handleExcelExportClick = React.useCallback(
    (columns: IColumn[]) => {
      const url = UrlService.getApiUrl(
        config.api.watchlist.issueExcelExportUrl
      );

      EndpointService.postBinary<Blob>(
        url,
        (request, data) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          saveAs(blob, "Issues.xlsx");
        },
        () => {
          notifications.error({
            message: intl.formatMessage(
              {
                id: "data_grid.footer.export.download.error",
                defaultMessage: "Downloading {name} failed"
              },
              {
                name: "Issues.xlsx"
              }
            )
          });
        },
        {
          search: filters.search,
          filters: filters.selects,
          order: mapColumns(columns)
        }
      );
    },
    [filters.search, filters.selects, intl, mapColumns]
  );

  const handleCsvExportClick = React.useCallback(
    (columns: IColumn[]) => {
      const url = UrlService.getApiUrl(config.api.watchlist.issueCsvExportUrl);
      EndpointService.postBinary<Blob>(
        url,
        (request, data) => {
          const blob = new Blob([data], {
            type: "application/csv"
          });
          saveAs(blob, "Issues.csv");
        },
        () => {
          notifications.error({
            message: intl.formatMessage(
              {
                id: "data_grid.footer.export.download.error",
                defaultMessage: "Downloading {name} failed"
              },
              {
                name: "Issues.csv"
              }
            )
          });
        },
        {
          search: filters.search,
          filters: filters.selects,
          order: mapColumns(columns)
        }
      );
    },
    [filters.search, filters.selects, intl, mapColumns]
  );

  return (
    <div className="grid-container data-grid__parent">
      <div className="data-grid__scroll">
        <Container size="lg">
          <div className="bootstrap-row">
            <div className="col-24 col-lg-24 header">
              {rowsTotal !== undefined && rowsTotal != null ? (
                <SectionName
                  messageDefault="Issues / {numberOfIssues, number} items"
                  messageId="issues_page.header.issues_total"
                  messageValues={{
                    numberOfIssues: rowsTotal
                  }}
                />
              ) : (
                <SectionName
                  messageDefault="Issues"
                  messageId="issues_page.header.issues"
                />
              )}
              <StatusBar />
            </div>
          </div>
          <div className="bootstrap-row">
            <div className="col-24 col-lg-24">
              <DataGrid
                actions={actions}
                columns={columns}
                dataEndpoint={dataEndpoint}
                exportToExcel={{
                  disabled:
                    !customerVisualizationsIssueGrid.data
                      ?.ExportToExcelRowLimit ||
                    customerVisualizationsIssueGrid.data
                      ?.ExportToExcelRowLimit < rowsTotal,
                  disabledMessage: intl.formatMessage(
                    {
                      id: "issues_page.grid.export_to_excel.disabled_message",
                      defaultMessage:
                        "Number of selected issues is too big. Max number of issues which can be exported to excel is {maxNumberOfIssues}. Limit number of issues using filter panel to export data."
                    },
                    {
                      maxNumberOfIssues: !customerVisualizationsIssueGrid.data
                        ?.ExportToExcelRowLimit
                        ? 0
                        : customerVisualizationsIssueGrid.data
                            ?.ExportToExcelRowLimit
                    }
                  ),
                  onClick: handleExcelExportClick
                }}
                exportToCsv={{
                  disabled: false,
                  onClick: handleCsvExportClick
                }}
              />
            </div>
          </div>
        </Container>
        <IssueStatusChangeConfirmationModalContainer
          visible={modalVisible}
          status={"Closed"}
          title={modalTitle}
          onConfirmAction={handleCloseIssueConfirm}
          hideModal={hideCloseIssueModal}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IState): IIssueGridState => ({
  user: getUserSelector(state)
});
const IssuesGridContainer = connect(mapStateToProps)(IssuesGrid);
export default IssuesGridContainer;
