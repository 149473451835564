// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import moment from "moment";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import TooltipWrapper, { Themes } from "common/tooltipwrapper/TooltipWrapper";

export enum WorkOrderType {
  Order = "order",
  Request = "request"
}

export interface IAction {
  id: string;
  description: string;
  scheduled: string;
  status: string;
  externalLink: string;
  type: WorkOrderType;
}

interface IActionProps {
  action: IAction;
}

const Action = ({ action }: IActionProps) => {
  const intl = useIntl();
  const maxLength = 100;
  const longDescription = action.description;
  const shortDescription =
    longDescription.length > maxLength
      ? `${longDescription.substr(0, maxLength)} ...`
      : longDescription;

  return (
    <div className="action-list-row action">
      <div className="id">
        <a href={action.externalLink} target="_blank" rel="noopener noreferrer">
          {action.id || "-"}
        </a>
        <ActionType>
          {intl.formatMessage({
            defaultMessage: `Work ${action.type}`,
            id: `detail_page.issues.actions.work.${action.type}`
          })}
        </ActionType>
      </div>
      <div className="description">
        {shortDescription !== longDescription && (
          <TooltipWrapper
            theme={Themes.Dark}
            Tooltip={() => (
              <ActionDescriptionTooltip description={longDescription} />
            )}
          >
            <div>{shortDescription}</div>
          </TooltipWrapper>
        )}
        {shortDescription === longDescription && shortDescription}
      </div>
      <div className="scheduled">
        {action.scheduled ? (
          <FormattedDate value={moment(action.scheduled).toDate()} />
        ) : (
          "-"
        )}
      </div>
      <div className="status">
        {action.status ? (
          <FormattedMessage
            defaultMessage={action.status}
            id={`issue.action.status.${action.status}`}
          />
        ) : (
          <span>-</span>
        )}
      </div>
    </div>
  );
};

const ActionType = styled.span`
  display: block;
  font-weight: normal;
`;

interface IActionDescriptionTooltipProps {
  description: string;
}

const ActionDescriptionTooltip = ({
  description
}: IActionDescriptionTooltipProps) => (
  <div className="action-description-tooltip">{description}</div>
);

export default Action;
