// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import moment, { max as maxDate, min as minDate, Moment } from "moment";
import IBushingOfflineParameterData from "../models/IBushingOfflineParameterData";

function getMinMaxDates(
  powerFactorData: IBushingOfflineParameterData,
  capacitanceData: IBushingOfflineParameterData
) {
  const dates = Object.keys({
    ...powerFactorData?.Readings,
    ...capacitanceData?.Readings
  });

  const momentDates: Moment[] = Array.from(dates).map((d) => moment(d));

  const firstDate = minDate(momentDates).toDate();
  const lastDate = maxDate(momentDates).toDate();

  return {
    firstDate,
    lastDate
  };
}

export default getMinMaxDates;
