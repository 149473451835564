// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Legend } from "@apm/widgets/build/components/BBChart";
import {
  colorPrimary,
  colorStatusGreen,
  colorStatusOrange,
  colorStatusRed,
  colorStatusYellow
} from "@pg/common/build/styles/ColorVariables";
import { Typography } from "antd";
import { FormattedMessage, IntlShape } from "react-intl";
import isKinectricsNetworkTransformerMineralOilModel from "../../../utils/isKinectricsNetworkTransformerMineralOilModel";

const { Text } = Typography;

const getGasStandardTranslation = (
  gasStandard: string,
  gasStandardRevision: string
) =>
  !gasStandardRevision ? (
    <FormattedMessage
      defaultMessage={`Gas Standard {gasStandard}`}
      id="detail_page.widgets.analytics.transformers.dga.gas_standard"
      values={{
        gasStandard
      }}
    />
  ) : (
    <FormattedMessage
      defaultMessage={`Gas Standard {gasStandard} {gasStandardRevision}`}
      id="detail_page.widgets.analytics.transformers.dga.gas_standard.revision"
      values={{
        gasStandard,
        gasStandardRevision: (
          <Text style={{ fontWeight: "normal" }}>({gasStandardRevision})</Text>
        )
      }}
    />
  );

const getConditionTranslation = (intl: IntlShape, conditionNumber: number) =>
  intl.formatMessage(
    {
      defaultMessage: "Condition {n}",
      id: "detail_page.widgets.analytics.transformers.dga.condition"
    },
    { n: conditionNumber }
  );

const getLegend =
  (
    intl: IntlShape,
    implementationId: string,
    gasStandard: string,
    gasStandardRevision: string
  ) =>
  (chartGroupName: string, dataSource: string) => {
    const isRatiosComposites = chartGroupName === "gasRatiosAndComposites";

    const legend: Legend = [];

    if (!isRatiosComposites) {
      legend.push({
        name: gasStandard,
        displayName: getGasStandardTranslation(
          gasStandard,
          gasStandardRevision
        ),
        isDataSeries: false,
        type: "info"
      });
    }

    legend.push({
      name: "offline_data",
      displayName: intl.formatMessage({
        defaultMessage: "Offline data",
        id: "detail_page.widgets.analytics.transformers.dga.offline_data"
      }),
      isDataSeries: true,
      type: "square",
      color: "black"
    });

    if (dataSource !== "offline") {
      legend.push({
        name: "online_data",
        displayName: intl.formatMessage({
          defaultMessage: "Online data",
          id: "detail_page.widgets.analytics.transformers.dga.online_data"
        }),
        isDataSeries: true,
        type: "square",
        color: colorPrimary
      });
    }

    const isIEEE = gasStandard === "IEEE";
    const isIEEE2019 = isIEEE && gasStandardRevision?.indexOf("2019") >= 0;
    const isKNTMOM =
          isKinectricsNetworkTransformerMineralOilModel(implementationId);
    const isETRA = gasStandard === "ETRA";
    const showCondition4 = !isRatiosComposites && !isIEEE2019 && !isKNTMOM && !isETRA;

    if (showCondition4) {
      legend.push({
        name: "condition-4",
        displayName: getConditionTranslation(intl, 4),
        isDataSeries: false,
        type: "region",
        color: colorStatusRed
      });
    }

    legend.push({
      name: "condition-3",
      displayName: getConditionTranslation(intl, 3),
      isDataSeries: false,
      type: "region",
      color: showCondition4 ? colorStatusOrange : colorStatusRed
    });

    legend.push({
      name: "condition-2",
      displayName: getConditionTranslation(intl, 2),
      isDataSeries: false,
      type: "region",
      color: isKNTMOM ? colorStatusOrange : colorStatusYellow
    });

    legend.push({
      name: "condition-1",
      displayName: getConditionTranslation(intl, 1),
      isDataSeries: false,
      type: "region",
      color: colorStatusGreen
    });

    return legend;
  };

export default getLegend;
