// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { VisualizationKeys } from "core/app/components/FeatureToggle";
import { createContext } from "react";

export interface IFeatureFlagsContext {
  isFeatureAvailable: (feature: VisualizationKeys) => boolean;
}

const FeatureFlagsContext = createContext<IFeatureFlagsContext>({
  isFeatureAvailable: () => false
});

export default FeatureFlagsContext;
