// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { notifications } from "@pg/common";
import { IDataEndpoint } from "common/datagrid/DataGrid";
import { SelectedFilters } from "common/FilterBar";
import { UserRoles } from "core/app/components/auth/Authorization";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import UrlService from "core/data/services/UrlService";
import { IssueStatuses } from "features/detailpage/features/issues/models/IssueStatuses";
import useCloseIssueAction from "features/issues/hooks/useCloseIssueAction";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { config } from "utils/AppConfig";
import useIssuesGridActionConfig, {
  IGridActionCallbacks
} from "./useIssueGridActionsConfig";
import useIssuesGridColumnsConfig, {
  IOnMaintenancePriorityClickOptions,
  IOnNumberOfActionsClickOptions
} from "./useIssuesGridColumnsConfig";

interface IUseGridOptions {
  filters: SelectedFilters;
  onMaintenancePriorityClick?: (
    options: IOnMaintenancePriorityClickOptions
  ) => void;
  onNumberOfActionsClick?: (options: IOnNumberOfActionsClickOptions) => void;
  user: IUser;
}

interface IIssueStatusChangeModalData {
  visible: boolean;
  assetIssueId?: string;
  title?: string;
  gridActionCallbacks?: IGridActionCallbacks;
}

const useIssueGrid = ({
  filters,
  onMaintenancePriorityClick,
  onNumberOfActionsClick,
  user
}: IUseGridOptions) => {
  const [rowsTotal, setRowsTotal] = useState<number>();
  const [modalData, setModalData] = useState<IIssueStatusChangeModalData>({
    visible: false
  });

  const closeIssue = useCloseIssueAction();
  const intl = useIntl();

  const showCloseIssueModal = useCallback(
    (
      assetIssueId: string,
      title: string,
      gridActionCallbacks: IGridActionCallbacks
    ) => {
      setModalData({
        visible: true,
        assetIssueId,
        title,
        gridActionCallbacks
      });
    },
    []
  );

  const hideCloseIssueModal = useCallback(() => {
    setModalData({ visible: false });
  }, []);

  const handleCloseIssueConfirm = useCallback(
    async (status: IssueStatuses, reason: string) => {
      try {
        if (modalData?.gridActionCallbacks?.onStarted)
          modalData.gridActionCallbacks.onStarted();
        await closeIssue(modalData?.assetIssueId, reason).then(() => {
          hideCloseIssueModal();
          notifications.success({
            message: intl.formatMessage({
              id: "issues_page.issue.close.success",
              defaultMessage: "Issue was closed"
            })
          });
          if (modalData?.gridActionCallbacks?.onSucceeded)
            modalData.gridActionCallbacks.onSucceeded();
        });
      } catch (e) {
        notifications.error({
          message: intl.formatMessage(
            {
              id: "issues_page.issue.close.failed",
              defaultMessage: "Cannot close issue. Response code: {code}"
            },
            { code: e.status }
          )
        });
        if (modalData?.gridActionCallbacks?.onFailed)
          modalData.gridActionCallbacks.onFailed();
      }
    },
    [
      closeIssue,
      hideCloseIssueModal,
      intl,
      modalData?.assetIssueId,
      modalData?.gridActionCallbacks
    ]
  );

  const columns = useIssuesGridColumnsConfig({
    onMaintenancePriorityClick,
    onNumberOfActionsClick
  });

  const isReadOnlyMode = useMemo(() => {
    return !AuthorizationService.isAuthorized(user, [
      UserRoles.Administrator,
      UserRoles.Engineer,
      UserRoles.LimitedEngineer
    ]);
  }, [user]);
  const defaultActions = useIssuesGridActionConfig(showCloseIssueModal);
  const actions = useMemo(
    () => (isReadOnlyMode ? [] : defaultActions),
    [defaultActions, isReadOnlyMode]
  );
  const dataEndpoint: IDataEndpoint = useMemo(
    () => ({
      url: UrlService.getApiUrl(config.api.watchlist.issuesRangeUrl),
      type: "POST",
      content: {
        search: filters.search,
        filters: filters.selects
      },
      onDataLoaded: (total) => {
        setRowsTotal(total);
      }
    }),
    [filters]
  );

  return {
    dataEndpoint,
    rowsTotal,
    actions,
    columns,
    hideCloseIssueModal,
    modalVisible: modalData.visible,
    modalTitle: modalData?.title,
    handleCloseIssueConfirm
  };
};

export default useIssueGrid;
